import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notify, sendRequest} from '../utils/functions';
import {vars} from '../utils/variables';
import CopyBlock from '../components/CopyBlock';
import QRCode from 'react-qr-code';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames/bind';

const {PAYMENT_SYSTEM_TYPE_ID, SUCCESS_API_CODE} = vars;


const labels = {
  BeneficiaryName: 'b_beneficiary_name',
  IBAN: 'b_account_number',
  BIC: 'b_bic',
  BankName: 'b_bank',
  Address: 'b_address',
  Description: 'b_description',
};

const MyBank = (props) => {
  console.log('props');
  console.log(props);
  const location = useLocation();
  const {t} = useTranslation();
  const [orderPayParams, setOrderPayParams] = useState(null);
  /*
  * {
    "BankName": "\"ПАТ МТБ БАНК\"",
    "Description": "Скоро сніг #U0NbeXxYeE",
    "IBAN": "UA093281680000026006000017304",
    "Address": "вул. Костянтинівська, оф. 67, Київ, Україна",
    "BeneficiaryName": "ФОП \"Зручний дім\"",
    "BIC": "328168",
    "URL": "https://bank.gov.ua/qr/CkJDRAowMDIKMQpVQ1QKCtCk0J7QnyAi0JfRgNGD0YfQvdC40Lkg0LTRltC8IgpVQTA5MzI4MTY4MDAwMDAyNjAwNjAwMDAxNzMwNApVQUg2MC4zMwoxMjM0NTY3ODk4CgoK0KHQutC-0YDQviDRgdC90ZbQsyAjVTBOYmVYeFllRQo="
}*/

  const getOrderPayParams = async () => {
    const request = await sendRequest('CheckoutOrder/GetOrderPayParams', {
      Params: {
        Urlid: props.order.Urlid,
        PaymentSystemTypeID: PAYMENT_SYSTEM_TYPE_ID.MTB_BANKING,
      }
    });

    if (request.ResponseCode === SUCCESS_API_CODE) {
      return request.Response.PayParams;
    } else {
      throw new Error(request.ResponseText || 'Error');
    }
  }

  const handleRedirect = () => {
    const currentUrl = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('from', '');
    const newPath = currentUrl.replace('/checkout/bank/', '/checkout/status/');

    const newUrl = `${newPath}?${searchParams.toString()}`;

    props.history.push(newUrl);
  }

  return (
    <div className={'req-wrapper'}>
      <div className={'req-inner'}>
        {orderPayParams ? (
          orderPayParams.map((param, index) => (
            <div key={`bank-requisites-${index}`} className={'bank-requisites'}>
              <div className={'req-item'}>
                {Object.entries(param).map(([key, value]) => {
                  if (key === 'URL') {
                    return (
                      <div key={`item-row-${index}`} className={'req-qr'}>
                        <span>{t('scan_qr')}</span>
                        <a href={value} target="_blank" rel="noopener noreferrer">
                          <QRCode value={value} size={150}/>
                        </a>
                      </div>
                    );
                  }

                  return (
                    <div key={`item-row-${index}`} className={'req-row'}>
                      <span className={'req-name'}>{t(labels[key])}</span>
                      <div className={'req-value'}>
                        <span>{value}</span>
                        <CopyBlock text={value}/>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <div className={'warning-text'}>
            <div className={'text-block'}>
              <p>Ви обрали оплату замовлення через додаток банку.</p>
            </div>
            <div className={'text-block'}>
              <p>На наступній сторінці перевірте вказані реквізити отримувача та відскануйте або натіснить на
                QR-код.</p>
            </div>
            <div className={'text-block'}>
              <p>Сканування з застосунку вашого банку - створить платіж.</p>
              <p>Сканування телефоном - переведе на сторінку НБУ з вибором банку.</p>
              <p>Після вибору банку, відкривається застосунок банку.</p>
            </div>
            <br></br>
            <div className={'text-block'}>
              <p>Підтвердження оплати</p>
              <p>Підвердіть переказ за вказаними реквізитами в мобільному-застосунку.</p>
            </div>
            <br/>
            <div className={'text-block'}>
              <p>УВАГА: Перед підтвердженням оплати в застосунку перевірте правильність реквізитів отримувача:</p>
              <p>Назву одержувача, Номер IBAN та Призначення платежу.</p>
            </div>
            <br/>
            <div className={'text-block'}>
              <p>Щоб продовжити натисніть "Продовжити".</p>
            </div>
          </div>
        )}
      </div>
      {orderPayParams ? (
        <div className={'req-action'}>
          <button className={'app-btn'} onClick={handleRedirect}>{t('i_paid')}</button>
        </div>
      ) : (
        <div className={'req-action'}>
          <button className={'app-btn cancel'} onClick={() => {
            props.history.goBack();
          }}>{t('cancel')}
          </button>
          <button className={'app-btn'} onClick={async () => {
            try {
              const payParams = await getOrderPayParams();
              setOrderPayParams(payParams);
            } catch (error) {
              notify(error);
            }
          }}>{t('next')}</button>
        </div>
      )}

    </div>
  );
}

export default MyBank;